<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.dealGoal"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
     
            <el-form ref="form" :model="form" :rules="rules">

                <div>
                  <el-row :gutter="20" class="mb-5 pb-5">
                    <el-col :span="4">
                       <el-form-item
                          :label="$t('message.year')"
                          class="label_mini mb-0"
                          prop="year"
                        >
                          <div>
                            <el-select size="medium" v-model="form.year" :value="form.year" placeholder="Select">
                              <el-option
                                v-for="item in options"
                                :key="item"
                                :label="item"
                                :value="item">
                              </el-option>
                            </el-select>
                          </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item
                          :label="$t('message.filial')"
                          class="label_mini mb-0"
                          prop="filial_id"
                        >
                          <select-filial
                            :size="'medium'"
                            :placeholder="$t('message.filial')"
                            :id="form.filial_id"
                            v-model="form.filial_id"
                          >
                          </select-filial>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item
                          :label="$t('message.currency')"
                          class="label_mini mb-0"
                          prop="currency_id"
                        >
                          <select-currency
                            :size="'medium'"
                            :placeholder="$t('message.currency')"
                            :id="form.currency_id"
                            v-model="form.currency_id"
                          >
                          </select-currency>
                        </el-form-item>
                    </el-col>
                    
                  </el-row>
                </div>

                <el-row :gutter="20" class="mb-3">
                    <el-col :span="6">
                      {{ $t('message.month') }}
                    </el-col>
                    <el-col :span="6">
                      {{ $t('message.count') }}
                    </el-col>
                    <el-col :span="6">
                        {{ $t('message.money_amount') }}
                    </el-col>
                    <el-col :span="6">  
                        {{ $t('message.profit') }}
                    </el-col>
                </el-row>
                <hr>
                <el-row :gutter="20" v-for="(month, index) in form.months" :key="month.id">
                    <el-col :span="4">
                      <span>{{ allMonths[index].month }}</span>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item
                      prop="count"
                      >
                        <el-input
                            :placeholder="$t('message.count')"
                            type="number"
                            :value="0"
                            v-model="month.count"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>

                    <el-col :span="6">
                      <el-form-item
                      prop="money_amount"
                      >
                        <el-input
                            type="number"
                            :placeholder="$t('message.money_amount')"
                            v-model="month.money_amount"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>

                    <el-col :span="6">
                      <el-form-item
                      prop="profit"
                      >
                        <el-input
                            type="number"
                            :placeholder="$t('message.profit')"
                            v-model="month.profit"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectCurrency from "@/components/inventory/select-currency";
import selectFilial from "@/components/inventory/select-filial";

export default {
    mixins: [form, drawer, show],
    name: "dealGoal",
    components: {
      selectCurrency,
      selectFilial,
    },
    data() {
      return {
        allMonths: [{month: this.$t('message.January')}, {month: this.$t('message.February')}, {month: this.$t('message.March')}, {month: this.$t('message.April')}, {month: this.$t('message.May')}, {month: this.$t('message.June')}, 
        {month: this.$t('message.July')}, {month: this.$t('message.August')}, {month: this.$t('message.September')}, {month: this.$t('message.October')}, {month: this.$t('message.November')}, {month: this.$t('message.December')}],  
        year: new Date().getFullYear(),
        options: [],
      };
    },
    created() {
      
    },
    watch: {
      watchAll: {
        handler: async function(newVal, oldVal) {
          if(this.form.filial_id && this.form.filial_id !== this.model.filial_id){
            this.updateGoalYears({year: this.form.year, filial_id: this.form.filial_id})
              .then(res => {
                  if(this.selectedYears.exists){
                    this.$message({
                      type: "warning",
                      message: this.selectedYears.message
                    });
                  }
              })
          }
        },
        deep: true,
        immediate: true
    },
  },
    computed: {
        ...mapGetters({
            rules: "dealGoals/rules",
            model: "dealGoals/model",
            columns: "dealGoals/columns",
            selectedYears: "dealGoals/years"
        }),
        watchAll(){
          return this.form.year + this.form.filial_id + 'aa'
        }
    },

    methods: {
        ...mapActions({
            update: "dealGoals/updateGoals",
            show: "dealGoals/showGoals",
            updateGoalYears: "dealGoals/selectedGoalYears",
        }),
        fetchData() {
            if (!this.loadingData) {  
                this.loadingData=true    
                this.show({year: this.selectedItem.year, filial_id: this.selectedItem.filial_id}).then(res => {   
                    this.year = this.form.year;
                    this.loadingData = false   
                    for (let i = 2000; i < 2100; i++) {
                        this.options.push(i);
                    }
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        submit(close = true) {
          if(!this.selectedYears.exists || this.form.filial_id === this.model.filial_id){
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
          }else{
            this.$message({
              type: "warning",
              message: this.selectedYears.message
            });
          }

        },
        afterLeave() {
          this.form = {};
          this.options = [];
        }
    },
};
</script>
